var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"clothes-list-item-box"},[_c('router-link',{staticClass:"clothes-list-item-pic",attrs:{"to":{ 
            name: 'clothesDetail', 
            query: { id: _vm.itemData.goods_id }
        }}},[_c('el-image',{staticClass:"clothes-list-item-pic-box",attrs:{"src":_vm.itemData.goods_img,"fit":"cover","lazy":""},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('div',{staticClass:"image-slot"},[_c('i',{staticClass:"el-icon-loading"})])]},proxy:true},{key:"error",fn:function(){return [_c('div',{staticClass:"image-slot"},[_c('i',{staticClass:"el-icon-picture-outline"})])]},proxy:true}])})],1),_c('div',{staticClass:"clothes-list-item-text"},[_c('div',{staticClass:"clothes-list-item-text-tag"},_vm._l((_vm.itemData.tag),function(item){return _c('span',{key:item.id},[_vm._v(" "+_vm._s(item)+" ")])}),0),_c('router-link',{staticClass:"clothes-list-item-text-title",attrs:{"to":{ 
                name: 'clothesDetail', 
                query: { id: _vm.itemData.goods_id }
            }}},[_vm._v(" "+_vm._s(_vm.itemData.goods_name)+" ")]),_c('div',{staticClass:"clothes-list-item-text-other"},[_c('div',{staticClass:"clothes-list-item-text-price"},[_c('span',[_vm._v(_vm._s(_vm.itemData.price))]),_c('span',[_vm._v("元/天")])]),_c('router-link',{staticClass:"clothes-list-item-text-reserve",attrs:{"to":{ 
                    name: 'clothesDetail', 
                    query: { id: _vm.itemData.goods_id }
                }}},[_vm._v(" 立即预定 "),_c('i',{staticClass:"el-icon-d-arrow-right"})])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }